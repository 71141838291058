@import "./variables.scss";

.summary {
    font-size: 1.2em;
    // margin-top: 1em;
    font-weight: 400;
    margin-bottom: 1em;
    color: $text-muted;
    max-width: $max-text-width;
}

.jsonLink {
    float: right;
}

.rssLink {
    float: right;
}

.helpLink {
    display: inline-block;
    padding-left: 0.1em;
}

.expandLink {
    color: $link-color;
    cursor: pointer;
}

.nullValue {
    color: $gray-400;
}

.spinner {
    width: 100%;
    text-align: center;
    padding-top: 3em;
    padding-bottom: 4em;
}

.spacer {
    font-weight: bolder;
    // color: $text-muted;
}

.formattedDate {
    white-space: nowrap;
}

.stickyParent {
    height: 100%;
    position: relative;
}

.stickyChild {
    position: sticky;
    top: $spacer * 2;
}

.explainer {
    margin-top: 2em !important;
}

.explainAnchor {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: $gray-400;
}

.explainContent {
    padding: $spacer * 0.2;
    text-align: left;
}